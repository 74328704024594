<template>
  <div>
    <b-modal
      id="modal-menu"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      size="lg"
      :title="sidebarTitle"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Title"
              invalid-feedback="Title is required."
              ref="title"
            >
              <b-form-input
                v-model.trim="myObj.title"
                placeholder="Enter title"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-for="(item, ind) in linkData" :key="ind">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Text"
                  invalid-feedback="Text is required."
                  ref="text"
                >
                  <b-form-input
                    v-model.trim="item.text"
                    placeholder="Enter text"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Redirect To"
                  invalid-feedback="Redirect To is required."
                >
                  <v-select
                    v-model="item.actionType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="actionOptions"
                    :reduce="(val) => val.value"
                    label="text"
                    :clearable="false"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="item.actionType == 'page'">
                <b-form-group
                  label="Page"
                  invalid-feedback="Page is required."
                  ref="page"
                >
                  <v-select
                    v-model="item.pageID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    label="title"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    placeholder="select page"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="item.actionType == 'url'">
                <b-form-group
                  label="URL"
                  invalid-feedback="URL is required."
                  ref="url"
                >
                  <b-form-input
                    v-model.trim="item.link"
                    placeholder="Enter url"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="mt-2">
                <b-button
                  v-if="ind == linkData.length - 1"
                  @click="AddVal()"
                  variant="primary"
                  class="btn-icon rounded-circle mr-50"
                >
                  <feather-icon size="18" icon="PlusIcon" />
                </b-button>
                <b-button
                  v-if="linkData.length > 1"
                  @click="removeVal(item, ind)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="18" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" class="text-right mt-1">
            <b-button variant="success" @click="Add()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-contact"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      size="lg"
      title="Contact Section"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Title"
              invalid-feedback="Title is required."
              ref="ctitle"
            >
              <b-form-input
                v-model.trim="myObj.title"
                placeholder="Enter title"
                @focusout="CheckCName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-for="(item, ind) in linkData" :key="ind">
            <b-row>
              <b-col
                :md="item.type == 'location' ? 6 : 12"
                v-if="item.actionType != 'social'"
              >
                <b-form-group
                  :label="item.type"
                  :invalid-feedback="item.type + ' is required.'"
                  label-class="text-capitalize"
                >
                  <b-form-input
                    v-model.trim="item.text"
                    :placeholder="'Your ' + item.type"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-else>
                <b-form-group
                  :label="item.type"
                  :invalid-feedback="item.type + ' is required.'"
                >
                  <b-form-input
                    v-model.trim="item.link"
                    :placeholder="'Your ' + item.type + ' link'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="item.type == 'location'">
                <b-form-group
                  label="Map Link"
                  invalid-feedback="Map Link is required."
                >
                  <b-form-input
                    v-model.trim="item.link"
                    placeholder="Enter link"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" class="text-right mt-1">
            <b-button
              variant="success"
              @click="saveContact()"
              :disabled="request"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-logo"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      size="sm"
      title="Footer Logo"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col>
            <b-form-group
              ref="image"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="logoObj.logo"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>

          <b-col md="12" class="text-right mt-1">
            <b-button
              variant="success"
              @click="saveLogo()"
              :disabled="saving || logoloading == true"
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card no-body>
      <b-row class="p-2">
        <b-col>
          <!-- <b-button @click="OpenAddModal(0)" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Section</span>
          </b-button> -->
          <b-button @click="openLogo()" variant="primary" :disabled="loading2">
            <span class="text-nowrap">Footer Logo</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="items"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="OpenAddModal(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteFlavour(data.item.footer.id)"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button> -->
          </template>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BSpinner,
    BFormCheckbox,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      dataLoading: true,
      fields: ["#", { label: "title", key: "footer.title" }, "actions"],
      items: [
        // {
        //   footer: {
        //     id: 1,
        //     title: "Appeals",
        //   },
        //   links: [
        //     { text: "Eductaion Fund", link: "#" },
        //     { text: "Food Fund", link: "#" },
        //     { text: "Water Fund", link: "#" },
        //   ],
        // },
      ],
      request: false,
      myObj: {
        id: 0,
        title: "",
      },
      linkData: [],
      visibility: false,
      sidebarTitle: "",
      actionOptions: [
        { text: "None", value: "none" },
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
      ],
      pageOptions: [],
      logoData: [],
      logoObj: {
        id: 0,
        logo: "",
        type: "footer",
      },
      saving: false,
      logoloading: false,
      loading2: false,
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
      this.LoadLogos();
    }
  },
  methods: {
    openLogo() {
      let obj = this.logoData.find((el) => el.type == "footer");
      // console.log(obj);
      if (obj) {
        this.logoloading = "loaded";
        this.logoObj = { ...obj };
      } else {
        this.logoloading = false;
        this.logoObj = {
          id: 0,
          logo: "",
          type: "footer",
        };
      }
      this.$bvModal.show("modal-logo");
    },
    selectlogo() {
      let obj = this.$refs.fileProfile.files[0];

      if (obj !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            var fn = e.data.myresp[0].path;

            this.logoObj.logo = fn;
            this.logoloading = "loaded";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      this.logoObj.logo = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    saveLogo() {
      if (this.logoObj.logo == "") {
        return this.$bvToast.toast("Please upload the logo", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.saving = true;
        if (this.logoObj.id == 0) {
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Logos",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.logoObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadLogos();
                this.$bvToast.toast("Logo saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.$bvModal.hide("modal-logo");
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        } else {
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Logos/" + this.logoObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.logoObj,
          };
          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadLogos();
                this.$bvToast.toast("Logo saved successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.$bvModal.hide("modal-logo");
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        }
      }
    },
    CheckName() {
      var elem = this.$refs["title"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLinks() {
      let state = true;
      this.linkData.forEach((el) => {
        if (
          el.text == "" ||
          (el.actionType == "url" && el.link == "") ||
          (el.actionType == "page" && el.pageID == 0)
        ) {
          state = false;
        }
      });
      return state;
    },

    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          title: "",
          type: "",
          status: "active",
        };
        this.linkData = [
          {
            id: 0,
            footerID: this.myObj.id,
            text: "",
            link: "",
            status: "active",
            type: "",
            actionType: "none",
            pageID: 0,
          },
        ];
        this.sidebarTitle = "Add Section";
      } else {
        this.myObj = { ...id.footer };
        if (this.myObj.type == "contact" && id.links.length == 0) {
          this.linkData = [
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "whatsapp",
              actionType: "",
              pageID: 0,
            },
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "email",
              actionType: "",
              pageID: 0,
            },
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "location",
              actionType: "",
              pageID: 0,
            },
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "twitter",
              actionType: "social",
              pageID: 0,
            },
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "instagram",
              actionType: "social",
              pageID: 0,
            },
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "facebook",
              actionType: "social",
              pageID: 0,
            },
          ];
        } else if (id.links.length == 0) {
          this.linkData = [
            {
              id: 0,
              footerID: this.myObj.id,
              text: "",
              link: "",
              status: "active",
              type: "",
              actionType: "none",
              pageID: 0,
            },
          ];
        } else this.linkData = id.links.map((el) => ({ ...el }));

        this.sidebarTitle = "Edit Section";
      }
      if (this.myObj.type == "contact") {
        this.$bvModal.show("modal-contact");
      } else this.$bvModal.show("modal-menu");
    },
    AddVal() {
      this.linkData.push({
        id: 0,
        footerID: this.myObj.id,
        text: "",
        link: "",
        status: "active",
        type: "",
        actionType: "none",
        pageID: 0,
      });
    },
    removeVal(item, ind) {
      if (item.id == 0) {
        this.linkData.splice(ind, 1);
      } else {
        var config = {
          method: "delete",
          url: "https://api.worldappeal.org.uk/api/FooterLinks/" + item.id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((response) => {
            if (response.data.status === "success") {
              this.linkData.splice(ind, 1);
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Footer/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Section has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    Add() {
      this.CheckName();

      if (this.CheckName() == false || this.CheckLinks() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.myObj);

        this.request = true;
        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/Footer/Save",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: {
            footer: this.myObj,
            links: this.linkData,
          },
        };

        axios(config)
          .then((response) => {
            this.request = false;
            if (response.data.status === "success") {
              // console.log(response.data);
              Swal.fire("Success!", "Section saved successfully.", "success");
              this.LoadData();
              this.$bvModal.hide("modal-menu");
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            this.request = false;
            console.log(error);
          });
      }
    },
    CheckCName() {
      var elem = this.$refs["ctitle"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCLinks() {
      let state = true;
      this.linkData.forEach((el) => {
        if (
          (el.actionType != "social" && el.text == "") ||
          (el.actionType == "social" && el.link == "")
        ) {
          state = false;
        }
      });
      return state;
    },
    saveContact() {
      this.CheckCName();

      if (this.CheckCName() == false || this.CheckCLinks() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.myObj, this.linkData);

        this.request = true;
        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/Footer/Save",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: {
            footer: this.myObj,
            links: this.linkData,
          },
        };

        axios(config)
          .then((response) => {
            this.request = false;
            if (response.data.status === "success") {
              // console.log(response.data);
              Swal.fire(
                "Success!",
                "Contact Section saved successfully.",
                "success"
              );
              this.LoadData();
              this.$bvModal.hide("modal-contact");
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            this.request = false;
            console.log(error);
          });
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Footer/LoadData",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadLogos() {
      this.loading2 = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Logos",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.logoData = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.loading2 = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
